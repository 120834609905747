<template>
  <router-view></router-view>
</template>

<script>
import { uiHelper, locale } from '@/util'
export default {
  name: 'CrashLayout',
  metaInfo: {
    title: `${locale.getMessage('meta.crash_title')}`,
    titleTemplate: `%s`,
    meta: [{ charset: 'utf-8' }, { name: 'description', content: `${locale.getMessage('meta.crash_description')}` }, { name: 'keyword', content: `${locale.getMessage('meta.crash_keyword')}` }],
    link: [
      {rel: 'canonical', href: 'https://'+ window.location.hostname+'/crash/'}
    ]
  },
  methods: {
    openLoginDialog() {
      this.$parent.openLoginDialog()
    }
  }
}
</script>
